import React from 'react'
import { Link, navigate } from 'gatsby'
import { motion } from 'framer-motion'
// import { AnchorLink } from 'gatsby-plugin-anchor-links'

class Pagina2 extends React.Component {
  render() {
    // console.log('history', history)
    return (
      <motion.div
        key="/"
        initial={{ opacity: 0, x: -400 }}
        animate={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0, x: -400 }}
        transition={{
          type: 'spring',
          mass: 0.35,
          stiffness: 75,
          duration: 5.3,
        }}
      >
        <h1>Pagina 2</h1>
        <div>
          {/* {!history.state && <Link to="/pagina1">Pagina 1</Link>}
          {history.state && (
            <a
              onClick={() => {
                typeof history !== 'undefined' && history.go(-1)
              }}
            >
              Torna a pagina 1
            </a>
          )} */}
        </div>
      </motion.div>
    )
  }
}

export default Pagina2
